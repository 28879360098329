<template>
  <div class="fourth-step">
    <h4 class="text-dark-darken">{{ title }}</h4>
    <div class="delivery-details mt-4">
      <div class="d-flex address-info">
        <map-marker color="var(--dark-info)" :orderNumber="1" class="marker-resume-address" />
        <div class="d-flex flex-column mx-3 flex-1">
          <span class="text-dark-darken font-weight-bold">Coleta</span>
          <small class="text-transition" v-html="sourceAddress"></small>
        </div>
        <uello-button
          class="edit-button"
          color="white"
          @click="$emit('edit', 1)"
          icon="pencil"
          title="Editar retirada"
        />
      </div>
      <div class="d-flex address-info mt-3">
        <map-marker color="var(--dark-info)" :orderNumber="2" class="marker-resume-address" />
        <div class="d-flex flex-column mx-3 flex-1">
          <span class="text-dark-darken font-weight-bold">Entrega</span>
          <small class="text-transition" v-html="destinationAddress"></small>
        </div>
        <uello-button
          class="edit-button"
          color="white"
          @click="$emit('edit', 2)"
          icon="pencil"
          title="Editar entrega"
        />
      </div>
      <div class="d-flex justify-content-between align-items-center mt-3 pt-3 border-top">
        <div class="d-flex flex-column">
          <span class="text-dark-darken font-weight-bold mb-1">{{ $t('stepper.customer') }}</span>
          <small
            v-for="[key, value] in customerData"
            v-show="!!value"
            :key="`user-info-${key}`"
            class="text-transition"
          >
            <b>{{ $t(`form.labels.${key}`) | capitalize }}:</b> {{ value }}
          </small>
        </div>
        <uello-button
          class="edit-button"
          color="white"
          @click="$emit('edit', 3)"
          icon="pencil"
          title="Editar retirada"
        />
      </div>

      <div class="d-flex justify-content-between align-items-center mt-3 pt-3 border-top">
        <div class="d-flex flex-column">
          <span class="text-dark-darken font-weight-bold mb-1">{{ $t('spot.boxSize') }}</span>
          <div class="d-flex flex-column mt-2">
            <span class="text-dark-info mb-2">Volumes: {{ volumes }}</span>
            <div
              class="d-flex align-items-center mb-1"
              v-for="(box, idx) in filteredBoxSizes"
              :key="`box-${idx}`"
            >
              <span class="text-dark-info ml-1 mr-3">{{ `${box.count}x` }}</span>
              <img svg-inline class="package" :src="getBoxUrl(box.image)" alt="BoxSize" />
              <span class="text-dark-info ml-3"
                >{{ $t(`form.labels.${box.image}`) | capitalize }} - {{ box.text }}</span
              >
            </div>
          </div>
        </div>
        <uello-button
          class="edit-button"
          color="white"
          @click="$emit('edit', 4)"
          icon="pencil"
          title="Editar retirada"
        />
      </div>
      <div class="d-flex align-items-center mt-4 py-3 border-top w-100">
        <div class="d-flex flex-column align-items-center border-right flex-1">
          <small class="text-transition">
            Tempo
          </small>
          <span class="text-dark-darken font-weight-bold">{{ eta }}</span>
        </div>
        <div class="d-flex flex-column align-items-center border-right flex-1">
          <small class="text-transition">
            Distância
          </small>
          <span class="text-dark-darken font-weight-bold">{{ distance }}</span>
        </div>
        <div class="d-flex flex-column align-items-center flex-1">
          <small class="text-transition">
            Valor a pagar
          </small>
          <span class="text-dark-darken font-weight-bold">{{ price }}</span>
        </div>
      </div>
      <div class="d-flex border-top">
        <div class="d-flex flex-column align-items-center flex-1">
          <small>
            <span class="text-dark-darken">{{ $t(`form.labels.estimatedValue`) }}</span>
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UelloButton } from '@uello/componentello';
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
export default {
  name: 'fourth-step',
  props: {
    title: {
      type: String,
      required: true,
    },
    source: {
      type: Object,
      required: true,
    },
    destination: {
      type: Object,
      required: true,
    },
    customer: {
      type: Object,
      required: true,
    },
    boxSizes: {
      type: Array,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
    distance: {
      type: String,
      required: true,
    },
    eta: {
      type: String,
      required: true,
    },
    volumes: {
      type: Number,
      required: true,
    },
  },
  components: {
    MapMarker: () => import('../u-map/MapMarker.vue'),
    UelloButton,
  },
  data() {
    return {};
  },
  watch: {},
  computed: {
    sourceAddress() {
      const { address, number, postcode, neighborhood, city, uf } = this.source;

      return `${address}, ${number} - ${neighborhood}\n<br> ${postcode} - ${city} - ${uf}`;
    },
    destinationAddress() {
      const { address, number, postcode, neighborhood, city, uf } = this.destination;

      return `${address}, ${number} - ${neighborhood}\n<br> ${postcode} - ${city} - ${uf}`;
    },
    customerData() {
      return Object.entries(this.customer);
    },
    filteredBoxSizes() {
      return this.boxSizes.filter(v => v.count);
    },
  },
  methods: {
    handleSubmit() {},
    changeValue() {},
    getBoxUrl(image) {
      const icon = require(`@/assets/images/${image}-package.svg`);
      return icon;
    },
  },
};
</script>

<style lang="scss">
.fourth-step {
  .marker-resume-address {
    max-width: 22px;
  }

  .edit-button {
    border: 0;
    background: transparent;
  }

  .package {
    max-width: 34px;
  }
}
</style>
